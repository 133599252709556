<template>
  <div class="bg-html">
    <Header />
    <main class="w-75 mx-auto mt-4 mb-4">
      <b-row>
        <b-col cols="12">
          <h2 class="text-center h2-title">
            {{ configurationPhrases.find(x => x.key === 'TITLE') == null ? '' : configurationPhrases.find(x => x.key === 'TITLE').value }}
          </h2>
          <p class="mb-0" v-html="configurationPhrases.find(x => x.key === 'DESCRIPTION') == null ? '' : configurationPhrases.find(x => x.key === 'DESCRIPTION').value">
          </p>
        </b-col>
      </b-row>
      <div class="min-heigth-53vh">
        <b-form @submit.stop.prevent="submit">
          <template>
            <HrSeparator title='Marca' id="hr-brand" />
            <b-row>
              <b-col cols="12">
                <p class="text-center">Selecione a(s) marca(s) da(s) sua(s) concessionária(s).</p>
                <div class="middle">
                  <div class="div-brand" v-for="brand in brands" :key="brand.id">
                    <label class="label-brand">
                      <input type="checkbox" :value='true' v-model='brand.selected' @click.stop="updateTables(brand.id)" :disabled="update">
                      <div class="box">
                        <div :id='`input-${brand.name}`'>
                          <template v-if="brand.selected">
                            <span class="d-block text-white text-uppercase font-35px mt-l8" v-if="brand.logo == ''">
                              {{ brand.name }}
                            </span>
                          </template>  
                          <span class="d-block" v-else>
                            {{ brand.name }}
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <div class="mt-2" v-for="brand in brands.filter(x => x.selected)" :key="`brand-collapsed-${brand.id}`">
            <b-row>
              <b-col cols="12">
                <b-button
                  :class="brand.collapsed ? null : 'collapsed'"
                  :aria-expanded="brand.collapsed ? 'true' : 'false'"
                  :aria-controls='`collapsed-${brand.id}`'
                  @click="brand.collapsed = !brand.collapsed"
                  variant="info" block
                  :disabled="update"
                >
                  {{ brand.name }} 
                  <i :class="`fa fa-arrow-${brand.collapsed ? 'up' : 'down'} float-right mt-4px`"></i>
                </b-button>
                <b-collapse :id='`collapsed-${brand.id}`' class="mt-2" v-model="brand.collapsed">
                  <b-card>
                    <template>
                      <HrSeparator title='Lojas' :id="`${brand.id}-stores`" />
                      <b-row>
                        <b-col :md="`${update ? '0' : '6'}`" cols="12">
                          <table class="table black-table">
                            <thead class="thead-dark">
                              <tr>
                                <th scope="col">Número de Lojas</th>
                                <th scope="col">Fee Mensal</th>
                                <!-- <th scope="col">*Multimarcas</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(brandPrice, index) in brandPrices.filter(x => x.brandId == brand.id && brand.form.model != null && brand.form.model == x.numberModel)" :key="`${brand.name}_${brandPrice.id}`" :id="`tr-${index}`" :class="(brand.selectedStart == index) ? 'background-gainsboro' : ''">
                                <td>{{ brandPrice.description }}</td>
                                <td>
                                  {{ new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                    minimumFractionDigits: 2
                                  }).format(brandPrice.price) }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-col>
                        <b-col md="6" cols="12" v-if="!update">
                          <table class="table black-table">
                            <thead class="thead-dark">
                              <tr>
                                <th colspan="2" scope="col">
                                  Descontos por quantidade de marcas
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(partnerDiscount, index) in partnerDiscounts.filter(x => x.numberBrand > 1 && x.active != 'False')" :key="`${partnerDiscount.id}_partnerDiscount`" :id="`tr-${index}`" :class="(amountBrands-2) == index ? 'background-gainsboro' : ''">
                                <td>{{ partnerDiscount.numberBrand }} Marcas</td>
                                <td>{{ formatPercentage(partnerDiscount.discount) }}%</td>
                              </tr>
                            </tbody>
                          </table>
                        </b-col>
                        <b-col cols="12" class="mb-2">
                          <b-form-text>
                            {{ configurationPhrases.find(x => x.key === 'MULTIBRANDS') == null ? '' : configurationPhrases.find(x => x.key === 'MULTIBRANDS').value }}
                          </b-form-text>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <b-form-group label="Número de Lojas:">
                            <b-form-input
                              :name="`brand-${brand.id}-numberStores`"
                              v-model="brand.form.numberStores"
                              type="number"
                              placeholder="Digite o Número de Lojas"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-numberStores`)"
                              data-vv-as="Número de Lojas"
                              @change="updateNumberStores(brand.id, brand.form.numberStores, brand.form.model)"
                              @focus="addFieldFocus(`brand-${brand.id}-numberStores`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-numberStores`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-numberStores`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-numberStores`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row style="display:none">
                        <b-col cols="12">
                          <b-form-group label="Modelos:">
                            <b-form-radio 
                              v-for="(radioBrands) in brandPrices.filter(x => x.brandId == brand.id).map(x => x.numberModel).filter((v, i, a) => a.indexOf(v) === i)" 
                              :key="`${radioBrands}_radio`" 
                              :name="`${brand.id}-model`"
                              v-model="brand.form.model" 
                              :value="radioBrands"
                            >
                              {{ radioBrands }} Modelos
                            </b-form-radio>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </template>
                    <template>
                      <HrSeparator title='Dados Cliente' :id="`${brand.name}-hr-client`" />
                      <b-row>
                        <b-col md="6" sm="12">
                          <b-form-group label="Nome Fantasia:">
                            <b-form-input
                              :name="`brand-${brand.id}-fantasyName`"
                              v-model="brand.form.fantasyName"
                              type="text"
                              placeholder="Digite o Nome Fantasia"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-fantasyName`)"
                              data-vv-as="Nome Fantasia"
                              @focus="addFieldFocus(`brand-${brand.id}-fantasyName`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-fantasyName`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-fantasyName`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-fantasyName`) }}</b-form-text>
                            <b-form-text>{{ configurationPhrases.find(x => x.key === 'DESCRIPTION-FANTASYNAME') == null ? '' : configurationPhrases.find(x => x.key === 'DESCRIPTION-FANTASYNAME').value }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                          <b-form-group label="Razão Social:">
                            <b-form-input
                              :name="`brand-${brand.id}-companyName`"
                              v-model="brand.form.companyName"
                              type="text"
                              placeholder="Digite a Razão Social"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-companyName`)"
                              data-vv-as="Nome Fantasia"
                              @focus="addFieldFocus(`brand-${brand.id}-companyName`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-companyName`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-companyName`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-companyName`) }}</b-form-text>
                            <b-form-text>{{ configurationPhrases.find(x => x.key === 'DESCRIPTION-COMPANYNAME') == null ? '' : configurationPhrases.find(x => x.key === 'DESCRIPTION-FANTASYNAME').value }}</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6" sm="12">
                          <b-form-group label="CNPJ:">
                            <b-form-input
                              :name="`brand-${brand.id}-cNPJ`"
                              v-model="brand.form.cNPJ"
                              type="text"
                              v-mask="'##.###.###/####-##'"
                              placeholder="Digite o CPNJ"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-cNPJ`)"
                              data-vv-as="CNPJ"
                              @focus="addFieldFocus(`brand-${brand.id}-cNPJ`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-cNPJ`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-cNPJ`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-cNPJ`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                          <b-form-group label="Ramo de Negócio:">
                            <b-form-input
                              :name="`brand-${brand.id}-business`"
                              v-model="brand.form.business"
                              type="text"
                              placeholder="Digite o Ramo de Negócio"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-business`)"
                              data-vv-as="Ramo de Negócio"
                              @focus="addFieldFocus(`brand-${brand.id}-business`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-business`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-business`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-business`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6" sm="12">
                          <b-form-group label="Inscrição Municipal:">
                            <b-form-input
                              :name="`brand-${brand.id}-municipalRegistration`"
                              v-model="brand.form.municipalRegistration"
                              type="text"
                              placeholder="Digite a Inscrição Municipal"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-municipalRegistration`)"
                              data-vv-as="Inscrição Municipal"
                              @focus="addFieldFocus(`brand-${brand.id}-municipalRegistration`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-municipalRegistration`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-municipalRegistration`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-municipalRegistration`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                          <b-form-group label="Inscrição Estadual:">
                            <b-form-input
                              :name="`brand-${brand.id}-stateRegistration`"
                              v-model="brand.form.stateRegistration"
                              type="text"
                              placeholder="Digite a Inscrição Estadual"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-stateRegistration`)"
                              data-vv-as="Inscrição Estadual"
                              @focus="addFieldFocus(`brand-${brand.id}-stateRegistration`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-stateRegistration`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-stateRegistration`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-stateRegistration`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12" sm="12">
                          <b-form-group label="Logradouro:">
                            <b-form-input
                              :name="`brand-${brand.id}-street`"
                              v-model="brand.form.street"
                              type="text"
                              placeholder="Digite o Logradouro"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-street`)"
                              data-vv-as="Logradouro"
                              @focus="addFieldFocus(`brand-${brand.id}-street`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-street`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-street`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-street`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6" sm="12">
                          <b-form-group label="Número:">
                            <b-form-input
                              :name="`brand-${brand.id}-numberAddress`"
                              v-model="brand.form.numberAddress"
                              type="text"
                              placeholder="Digite o Número"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-numberAddress`)"
                              data-vv-as="Número"
                              @focus="addFieldFocus(`brand-${brand.id}-numberAddress`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-numberAddress`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-numberAddress`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-numberAddress`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                          <b-form-group label="Complemento:">
                            <b-form-input
                              :name="`brand-${brand.id}-complement`"
                              v-model="brand.form.complement"
                              type="text"
                              placeholder="Digite o Complemento"
                              :state="validateState(`brand-${brand.id}-complement`)"
                              data-vv-as="Complemento"
                              @focus="addFieldFocus(`brand-${brand.id}-complement`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-complement`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-complement`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-complement`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6" sm="12">
                          <b-form-group label="CEP:">
                            <b-form-input
                              :name="`brand-${brand.id}-zipCode`"
                              v-model="brand.form.zipCode"
                              type="text"
                              v-mask="'#####-###'"
                              placeholder="Digite a CEP"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-zipCode`)"
                              data-vv-as="CEP"
                              @focus="addFieldFocus(`brand-${brand.id}-zipCode`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-zipCode`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-zipCode`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-zipCode`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                          <b-form-group label="Bairro:">
                            <b-form-input
                              :name="`brand-${brand.id}-district`"
                              v-model="brand.form.district"
                              type="text"
                              placeholder="Digite o Bairro"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-district`)"
                              data-vv-as="Bairro"
                              @focus="addFieldFocus(`brand-${brand.id}-district`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-district`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-district`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-district`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6" sm="12">
                          <b-form-group label="Cidade:">
                            <b-form-input
                              :name="`brand-${brand.id}-city`"
                              v-model="brand.form.city"
                              type="text"
                              placeholder="Digite a Cidade"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-city`)"
                              data-vv-as="Cidade"
                              @focus="addFieldFocus(`brand-${brand.id}-city`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-city`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-city`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-city`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                          <b-form-group label="Estado:">
                            <b-form-input
                              :name="`brand-${brand.id}-state`"
                              v-model="brand.form.state"
                              type="text"
                              placeholder="Digite o Estado"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-state`)"
                              data-vv-as="Estado"
                              @focus="addFieldFocus(`brand-${brand.id}-state`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-state`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-state`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-state`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <b-form-group label="URL do Site:">
                            <b-form-input
                              :name="`brand-${brand.id}-website`"
                              v-model="brand.form.website"
                              type="text"
                              placeholder="Digite a URL do Site"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-website`)"
                              data-vv-as="URL do Site"
                              @focus="addFieldFocus(`brand-${brand.id}-website`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-website`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-website`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-website`) }}</b-form-text>
                            <b-form-text>Exemplo: https://www.essencemediacom.com.br/</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </template>
                    <template>
                      <HrSeparator title='Contato Financeiro' :id="`${brand.id}hr-financial`" />
                      <b-row v-for="(financialContact, contactIndex) in brand.form.financialContacts" :key="`contact-financial-${contactIndex}`">
                        <b-col md="4" sm="12">
                          <b-form-group label="Nome:">
                            <b-form-input
                              :name="`brand-${brand.id}-financialContacts-${contactIndex}-name`"
                              v-model="financialContact.name"
                              type="text"
                              placeholder="Digite o Nome"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-financialContacts-${contactIndex}-name`)"
                              :data-vv-as="`Nome do Contato Financeiro ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-financialContacts-${contactIndex}-name`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-financialContacts-${contactIndex}-name`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-financialContacts-${contactIndex}-name`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-financialContacts-${contactIndex}-name`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="4" sm="12">
                          <b-form-group label="Email:">
                            <b-form-input
                              :name="`brand-${brand.id}-financialContacts-${contactIndex}-mail`"
                              v-model="financialContact.mail"
                              type="email"
                              placeholder="Digite o Email"
                              v-validate="{ required: true, email: true }"
                              :state="validateState(`brand-${brand.id}-financialContacts-${contactIndex}-mail`)"
                              :data-vv-as="`Email do Contato Financeiro ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-financialContacts-${contactIndex}-mail`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-financialContacts-${contactIndex}-mail`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-financialContacts-${contactIndex}-mail`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-financialContacts-${contactIndex}-mail`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="3" sm="12">
                          <b-form-group label="Telefone:">
                            <b-form-input
                              :name="`brand-${brand.id}-financialContacts-${contactIndex}-phone`"
                              v-model="financialContact.phone"
                              type="text"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                              placeholder="Digite o seu Telefone"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-financialContacts-${contactIndex}-phone`)"
                              :data-vv-as="`Telefone do Contato Financeiro ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-financialContacts-${contactIndex}-phone`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-financialContacts-${contactIndex}-phone`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-financialContacts-${contactIndex}-phone`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-financialContacts-${contactIndex}-phone`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="1" sm="12" class="mt-31px">
                          <b-button :disabled="brand.form.financialContacts.length <= 1" @click="removeContact(brand, contactIndex, 'financial')" variant="danger">
                            <i class="fa fa-minus"></i>
                          </b-button>
                          <b-button @click="addContact(brand, 'financial')" variant="primary" class="ml-1">
                            <i class="fa fa-plus"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <template>
                      <HrSeparator title='Contato Representante Legal' :id="`${brand.id}hr-legal`" />
                      <b-row v-for="(legalRepresentativeContact, contactIndex) in brand.form.legalRepresentativeContacts" :key="`legal-representative-financial-${contactIndex}`">
                        <b-col md="4" sm="12">
                          <b-form-group label="Nome:">
                            <b-form-input
                              :name="`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-name`"
                              v-model="legalRepresentativeContact.name"
                              type="text"
                              placeholder="Digite o Nome"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-name`)"
                              :data-vv-as="`Nome do Contato Representante Legal ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-name`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-name`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-name`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-name`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="4" sm="12">
                          <b-form-group label="Email:">
                            <b-form-input
                              :name="`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-mail`"
                              v-model="legalRepresentativeContact.mail"
                              type="email"
                              placeholder="Digite o Email"
                              v-validate="{ required: true, email: true }"
                              :state="validateState(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-mail`)"
                              :data-vv-as="`Email do Contato Representante Legal ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-mail`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-mail`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-mail`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-mail`) }}</b-form-text>
                            <b-form-text>Este será o email responsável pelo retorno do cadastro deste preenchimento</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="3" sm="12">
                          <b-form-group label="Telefone:">
                            <b-form-input
                              :name="`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-phone`"
                              v-model="legalRepresentativeContact.phone"
                              type="text"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                              placeholder="Digite o seu Telefone"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-phone`)"
                              :data-vv-as="`Telefone do Contato Representante Legal ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-phone`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-phone`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-phone`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-legalRepresentativeContacts-${contactIndex}-phone`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="1" sm="12" class="mt-31px">
                          <b-button :disabled="brand.form.legalRepresentativeContacts.length <= 1" @click="removeContact(brand, contactIndex, 'legalRepresentative')" variant="danger">
                            <i class="fa fa-minus"></i>
                          </b-button>
                          <b-button @click="addContact(brand, 'legalRepresentative')" variant="primary" class="ml-1">
                            <i class="fa fa-plus"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <template>
                      <HrSeparator title='Mídia - Principal Contato para assuntos envolvendo a operação' :id="`${brand.id}-hr-medium`" />
                      <b-row v-for="(mediumContact, contactIndex) in brand.form.mediumContacts" :key="`contact-medium-${contactIndex}`">
                        <b-col md="4" sm="12">
                          <b-form-group label="Nome:">
                            <b-form-input
                              :name="`brand-${brand.id}-mediumContacts-${contactIndex}-name`"
                              v-model="mediumContact.name"
                              type="text"
                              placeholder="Digite o Nome"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-mediumContacts-${contactIndex}-name`)"
                              :data-vv-as="`Nome do Principal Contato para assuntos envolvendo a operação ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-mediumContacts-${contactIndex}-name`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-mediumContacts-${contactIndex}-name`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-mediumContacts-${contactIndex}-name`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-mediumContacts-${contactIndex}-name`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="4" sm="12">
                          <b-form-group label="Email:">
                            <b-form-input
                              :name="`brand-${brand.id}-mediumContacts-${contactIndex}-mail`"
                              v-model="mediumContact.mail"
                              type="email"
                              placeholder="Digite o Email"
                              v-validate="{ required: true, email: true }"
                              :state="validateState(`brand-${brand.id}-mediumContacts-${contactIndex}-mail`)"
                              :data-vv-as="`Email do Principal Contato para assuntos envolvendo a operação ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-mediumContacts-${contactIndex}-mail`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-mediumContacts-${contactIndex}-mail`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-mediumContacts-${contactIndex}-mail`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-mediumContacts-${contactIndex}-mail`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="3" sm="12">
                          <b-form-group label="Telefone:">
                            <b-form-input
                              :name="`brand-${brand.id}-mediumContacts-${contactIndex}-phone`"
                              v-model="mediumContact.phone"
                              type="text"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                              placeholder="Digite o seu Telefone"
                              v-validate="{ required: true }"
                              :state="validateState(`brand-${brand.id}-mediumContacts-${contactIndex}-phone`)"
                              :data-vv-as="`Telefone do Principal Contato para assuntos envolvendo a operação ${contactIndex + 1}`"
                              @focus="addFieldFocus(`brand-${brand.id}-mediumContacts-${contactIndex}-phone`)"
                              @blur="removeFieldFocus(`brand-${brand.id}-mediumContacts-${contactIndex}-phone`)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-mediumContacts-${contactIndex}-phone`) }}</b-form-invalid-feedback>
                            <b-form-text>{{ getErrorDescription(`brand-${brand.id}-mediumContacts-${contactIndex}-phone`) }}</b-form-text>
                          </b-form-group>
                        </b-col>
                        <b-col md="1" sm="12" class="mt-31px">
                          <b-button :disabled="brand.form.mediumContacts.length <= 1" @click="removeContact(brand, contactIndex, 'medium')" variant="danger">
                            <i class="fa fa-minus"></i>
                          </b-button>
                          <b-button @click="addContact(brand, 'medium')" variant="primary" class="ml-1">
                            <i class="fa fa-plus"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <template>
                      <HrSeparator title='Contrato Social' :id="`${brand.id}-hr-contract`" />
                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="Contrato social:"
                            description="Arquivos aceitos: .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                          >
                            <template v-if="update && !upload">
                              <b-button 
                                v-b-tooltip.hover title="Download"
                                @click.stop.prevent="getSocialContract()" 
                                variant="secondary"
                                class="mr-2">
                                <i class="fa fa-download"></i>
                              </b-button>
                              <b-button 
                                v-b-tooltip.hover title="Remover Arquivo"
                                @click.stop.prevent="upload = true" 
                                variant="danger">
                                <i class="fa fa-times"></i>
                              </b-button>
                            </template>
                            <template v-else>
                              <b-form-file 
                                :name="`brand-${brand.id}-socialContract`"
                                v-model="brand.form.socialContract"
                                placeholder="Escolha os arquivos ou arraste-os aqui"
                                accept=".jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                                description="Arquivos aceitos: .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                                v-validate="{ required: true }"
                                :state="validateState(`brand-${brand.id}-socialContract`)"
                                data-vv-as="Contrato Social"
                                @change="removeFieldFocus(`brand-${brand.id}-socialContract`)"
                              ></b-form-file>
                              <b-form-invalid-feedback>{{ getError(`brand-${brand.id}-socialContract`) }}</b-form-invalid-feedback>
                              <b-form-text>{{ getErrorDescription(`brand-${brand.id}-socialContract`) }}</b-form-text>
                            </template>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </template>
                    <template>
                      <HrSeparator title='Contrato de Prestação de Serviços' :id="`${brand.id}-hr-comercial`" />
                      <b-row>
                        <b-col cols="12">
                          <b-button 
                            v-b-tooltip.hover title="Download do Contrato"
                            @click.stop.prevent="getAgreement(brand.id)" 
                            variant="info"
                            class="mr-2">
                            <i class="fa fa-file-pdf-o"></i>
                          </b-button>
                          <span>
                            Contrato de Prestação de Serviços de Gerenciamento de Campanhas Publicitárias Online
                          </span>
                          <b-form-text>
                            {{ configurationPhrases.find(x => x.key === 'DESCRIPTION-AGREEMENTCONTRACT') == null ? '' : configurationPhrases.find(x => x.key === 'DESCRIPTION-AGREEMENTCONTRACT').value }}
                          </b-form-text>
                          <b-form-text>
                            {{ configurationPhrases.find(x => x.key === 'DESCRIPTION-AGREEMENTCONTRACT-DOUBT') == null ? '' : configurationPhrases.find(x => x.key === 'DESCRIPTION-AGREEMENTCONTRACT-DOUBT').value }}
                          </b-form-text>
                        </b-col>
                        <b-col cols="12" class="mt-2">
                          <b-form-checkbox
                            :name="`${brand.id}-Terms`"
                            v-model="brand.form.terms"
                            :value="true"
                            :unchecked-value="false"
                          >
                            Confirmo que li e aceito o contrato de serviços e suas cláusulas comerciais.
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </template>
                  </b-card>
                </b-collapse>
              </b-col>
            </b-row>
          </div>
          <b-button v-if="brands.find(x => x.selected) != null" variant="info" class="mt-3 d-block mx-auto" @click="submit">
            Enviar
          </b-button>
        </b-form>
      </div>
    </main>
    <Footer />
    <div class="spinner" v-if="spinner">
      <Spinner class="spinner-item" />
    </div>
  </div>
</template>

<script>
import BrandService from '@/service/BrandService'
import DealerService from '@/service/DealerService'
import ConfigurationService from '@/service/ConfigurationService'
import TokenService from '@/service/TokenService'

import Swal from 'sweetalert2'
import Spinner from '@/components/Spinner'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import HrSeparator from '@/components/HrSeparator'
import { mask } from 'vue-the-mask'

export default {
  components: { Spinner, HrSeparator, Header, Footer },
  directives: { mask },
  data() {
    return {
      brandService: null,
      dealerService: null,
      configurationService: null,
      tokenService: null,

      brands: [],
      brandPrices: [],
      configurationPhrases: [],
      errors: [],
      fieldFocus: [],
      partnerDiscounts: [],
      spinner: false,
      update: false,
      upload: true,
      amountBrands: 0
    }
  },
  methods: {
    addContact (brand, type) {
      switch (type) {
        case 'financial':
          brand.form.financialContacts.push(
            { name: '', mail: '', phone: '' }
          )
          break;
        case 'legalRepresentative':
          brand.form.legalRepresentativeContacts.push(
            { name: '', mail: '', phone: '' }
          )
          break;
        case 'medium':
          brand.form.mediumContacts.push(
            { name: '', mail: '', phone: '' }
          )
          break;
        default:
          break;
      }
      this.$forceUpdate();
    },
    addFieldFocus (field) {
      this.fieldFocus.push(field)
    },
    cnpjValidation(value) {
      if (!value) return false

      const validTypes =
        typeof value === 'string' || Number.isInteger(value) || Array.isArray(value)

      if (!validTypes) return false

      const match = value.toString().match(/\d/g)
      const numbers = Array.isArray(match) ? match.map(Number) : []

      if (numbers.length !== 14) return false
      
      const items = [...new Set(numbers)]
      if (items.length === 1) return false
      
      const calc = (x) => {
        const slice = numbers.slice(0, x)
        let factor = x - 7
        let sum = 0

        for (let i = x; i >= 1; i--) {
          const n = slice[x - i]
          sum += n * factor--
          if (factor < 2) factor = 9
        }

        const result = 11 - (sum % 11)

        return result > 9 ? 0 : result
      }
      
      const digits = numbers.slice(12)
      
      const digit0 = calc(12)
      if (digit0 !== digits[0]) return false
      
      const digit1 = calc(13)
      return digit1 === digits[1]
    },
    formatPercentage (value) {
      return parseInt(value).toFixed(0)
    },
    getAuth (token) {
      this.spinner = true
      this.tokenService.auth()
        .then(response => {
          localStorage.setItem('auth', response.token)
          this.getConfigutarionPhrase()
          if (token) {
            this.update = true
            this.upload = false
            this.getDealerByToken(token)
          } else {
            this.getPartnerDiscount()
            this.getBrands()
          }
        })
    },
    getAgreement (brandId) {
      this.spinner = true
      this.brandService.getAgreement(brandId)
        .then(blob => {
          window.open(window.URL.createObjectURL(blob))
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.spinner = false
        })
    },
    getBrands (dealer, token) {
      this.brandService.getAll()
        .then(response => {
          if (dealer) {
            this.update = true
            const dealerContract = dealer.dealerContractModels[0]
            this.errors = JSON.parse(dealerContract.disapprovalNote)
            for (let [key, value] of Object.entries(this.errors)) {
              if (value.selected) {
                if (key === 'socialContract') {
                  this.upload = true
                }
                this.addFieldFocus(`brand-${dealer.brandId}-${key}`)
              }
            }
            this.brands = response.filter(x => x.id == dealer.brandId).map(x => {
              const financialContacts = dealerContract.dealerContactModels.filter(y => y.contactTypeId == 1).map(z => {
                return {
                  id: z.id,
                  name: z.name,
                  mail: z.mail,
                  phone: z.phone
                }
              })
              const legalRepresentativeContacts = dealerContract.dealerContactModels.filter(y => y.contactTypeId == 2).map(z => {
                return {
                  id: z.id,
                  name: z.name,
                  mail: z.mail,
                  phone: z.phone
                }
              })
              const mediumContacts = dealerContract.dealerContactModels.filter(y => y.contactTypeId == 3).map(z => {
                return {
                  id: z.id,
                  name: z.name,
                  mail: z.mail,
                  phone: z.phone
                }
              })
              return {
                id: x.id,
                partnerId: x.partnerId,
                name: x.name,
                logo: x.logo,
                selected: true,
                collapsed: true,
                selectedStart: -1,
                form: {
                  token,
                  dealerId: dealer.id,
                  dealerPrincipalId: dealer.dealerPrincipalId,
                  dealerContractId: dealerContract.id,
                  brandId: x.id,
                  numberStores: dealerContract.numberStore,
                  model: dealerContract.numberModel,
                  fantasyName: dealerContract.fantasyName,
                  companyName: dealerContract.companyName,
                  cNPJ: dealerContract.cnpj,
                  business: dealerContract.business,
                  municipalRegistration: dealerContract.municipalRegistration,
                  stateRegistration: dealerContract.stateRegistration,
                  street: dealerContract.street,
                  numberAddress: dealerContract.numberAddress,
                  complement: dealerContract.complement,
                  zipCode: dealerContract.zipCode,
                  district: dealerContract.district,
                  city: dealerContract.city,
                  state: dealerContract.state,
                  website: dealerContract.website,
                  financialContacts: financialContacts ?? [],
                  legalRepresentativeContacts: legalRepresentativeContacts ?? [],
                  mediumContacts: mediumContacts ?? [],
                  terms: false,
                  socialContract: null
                }
              }
            })
            this.getBrandPrices([dealer.brandId], dealer.brandId, true, dealerContract.numberStore, dealerContract.numberModel)
          } else {
            this.update = false
            this.brands = response.map(x => {
              return {
                id: x.id,
                partnerId: x.partnerId,
                name: x.name,
                logo: x.logo,
                selected: false,
                collapsed: false,
                selectedStart: -1,
                form: {
                  brandId: x.id,
                  numberStores: null,
                  model: 1,
                  fantasyName: null,
                  companyName: null,
                  cNPJ: null,
                  business: null,
                  municipalRegistration: null,
                  stateRegistration: null,
                  street: null,
                  numberAddress: null,
                  complement: null,
                  zipCode: null,
                  district: null,
                  city: null,
                  state: null,
                  website: null,
                  financialContacts: [
                    { name: null, mail: null, phone: null }
                  ],
                  legalRepresentativeContacts: [
                    { name: null, mail: null, phone: null }
                  ],
                  mediumContacts: [
                    { name: null, mail: null, phone: null }
                  ],
                  terms: false,
                  socialContract: null
                }
              }
            })
          }
        })
        .finally(() => {
          this.spinner = false
        })
    },
    getBrandPrices (brandIds, brandId, notUpdate, numberStore, numberModel) {
      if (brandIds.length > 0) {
        this.spinner = true

        let result = ''
        for (let i = 0; i < brandIds.length; i++) {
            result  += `brandIds=${brandIds[i]}&`
        }
        const query = result.slice(0, -1)

        this.brandService.getPrices(query)
          .then(response => {
            this.brandPrices = []
            this.brandPrices = response
            if (!notUpdate) {
              this.brands.forEach(x => {
                if (x.id == brandId) {
                  x.form.model = 1
                  // const brandPrice = this.brandPrices.find(y => y.brandId = x.id)
                  // x.form.model = brandPrice.numberModel
                }
              })
            } else {
              this.updateNumberStores(brandId, numberStore, numberModel)
            }
            this.spinner = false
          })
          .finally(() => {
            this.spinner = false
          })
      }
    },
    getDealerByToken (token) {
      this.dealerService.getByToken(token)
        .then(response => {
          this.getBrands(response, token)
        })
        .catch(() => {
          this.spinner = false
          Swal.fire({
            title: 'Erro',
            text: 'Token Expirado',
            icon: 'error'
          }).then(() => {
            this.$router.push(`/`)
            location.reload()
          })
        })
    },
    getError (field) {
      if (field.split('-').length === 3) {
        const fieldName = field.split('-')[2]
        if (this.fieldFocus.find(x => `${x.split('-')[2]}` === fieldName) != null) if (this.errors[fieldName].selected) return this.errors[fieldName].text
      } else {
        const fieldName = `${field.split('-')[2]}-${field.split('-')[3]}-${field.split('-')[4]}`
        if (this.fieldFocus.find(x => `${x.split('-')[2]}-${x.split('-')[3]}-${x.split('-')[4]}` === fieldName) != null) if (this.errors[fieldName].selected) return this.errors[fieldName].text
      }
      if (field.includes('cNPJ')) {
        const brandSelected = field.split('-')[1]
        const cnpj = this.brands.find(x => x.id == brandSelected).form.cNPJ
        if (cnpj != null)
          return 'O Campo de CNPJ deve ser válido'
      }
      return this.veeErrors.first(field)
    },
    getErrorDescription (field) {
      if (!this.update || this.fieldFocus.some(x => x === field)) return ''
      if (field.split('-').length === 3) {
        const fieldName = field.split('-')[2]
        return this.errors[fieldName].text
      } else {
        const fieldName = `${field.split('-')[2]}-${field.split('-')[3]}-${field.split('-')[4]}`
        if (this.errors[fieldName] != null) return this.errors[fieldName].text
      }
    },
    getConfigutarionPhrase () {
      this.configurationService.getAll()
        .then(response => {
          this.configurationPhrases = response
        })
    },
    getPartnerDiscount () {
      this.configurationService.getAllPartnerDiscount()
        .then(response => {
          this.partnerDiscounts = response
        })
    },
    getSocialContract () {
      this.spinner = true
      this.dealerService.getSocialContract(this.brands[0].form.id)
        .then(blob => {
          window.open(window.URL.createObjectURL(blob))
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.spinner = false
        })
    },
    removeFieldFocus (field) {
      this.fieldFocus = this.fieldFocus.filter(x => x !== field)
    },
    submit () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        const form = this.brands.filter(x => x.selected).map(x => x.form)

        if (form.some(x => !x.terms)) {
          Swal.fire({
            title: 'Atenção',
            text: 'É necessário aceitar os termos e condições de uso',
            icon: 'warning'
          })
          return
        }

        this.spinner = true
        
        this.dealerService.create(form)
          .then(response => {
            if (response.status != 204)
              throw new Error()
            
            Swal.fire({
              title: 'Sucesso',
              text: 'Formulário enviado com sucesso!',
              icon: 'success'
            }).then(() => {
              this.spinner = true
              location.reload()
            })
          })
          .catch(() => {
            Swal.fire({
              title: 'Erro',
              text: 'Erro inesperado',
              icon: 'error'
            })
          })
          .finally(() => {
            this.spinner = false
          })
      })
    },
    removeContact (brand, contactIndex, type) {
      switch (type) {
        case 'financial':
          brand.form.financialContacts.splice(contactIndex, 1)
          break;
        case 'legalRepresentative':
          brand.form.legalRepresentativeContacts.splice(contactIndex, 1)
          break;
        case 'medium':
          brand.form.mediumContacts.splice(contactIndex, 1)
          break;
        default:
          break;
      }
      this.$forceUpdate();
    },
    updateNumberStores(brandId, numberStores, numberModel) {
      const brandNumberStores = this.brandPrices.filter(x => x.brandId == brandId && numberModel != null && numberModel == x.numberModel).map(x => parseInt(x.upToNumber))
      let selected = -1
      let updateValue = false
      if (numberStores != null) {
        let value = 0
        brandNumberStores.forEach(x => {
          if (!updateValue && x >= parseInt(numberStores)) {
            value = x
            updateValue = true
          }
        })
        selected = brandNumberStores.indexOf(value)
      }
      this.brands.find(x => x.id == brandId).selectedStart = selected
    },
    updateTables (brandId) {
      let isSelected = false
      this.brands.forEach(item => {
        if (item.id == brandId) {
          item.selected = item.selected ? false : true
          isSelected = item.selected
        }
      })
      const brandIdsSelected = this.brands.filter(x => x.selected)
      this.amountBrands = this.brands.filter(x => x.selected).length
      
      isSelected ? this.getBrandPrices(brandIdsSelected.map(x => x.id), brandId) : this.getBrandPrices(brandIdsSelected.map(x => x.id))
      // this.getBrandPrices(brandIdsSelected.map(x => x.id))
    },
    validateState (ref) {
      if(ref.split('-').length === 3) {
        const fieldName = ref.split('-')[2]
        if (this.fieldFocus.find(x => `${x.split('-')[2]}` === fieldName) != null) if (this.errors[fieldName].selected) return !this.errors[fieldName].selected
      } else {
        const fieldName = `${ref.split('-')[2]}-${ref.split('-')[3]}-${ref.split('-')[4]}`
        if (this.fieldFocus.find(x => `${x.split('-')[2]}-${x.split('-')[3]}-${x.split('-')[4]}` === fieldName) != null) if (this.errors[fieldName].selected) return !this.errors[fieldName].selected
      }
      if (ref.includes('cNPJ')) {
        const brandSelected = ref.split('-')[1]
        const cnpj = this.brands.find(x => x.id == brandSelected).form.cNPJ
        if (cnpj != null)
          return this.cnpjValidation(cnpj)
      }
      if (this.veeFields[ref] &&(this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  },
  async mounted() {
    localStorage.removeItem('auth')
    document.title = 'Stellantis - Adesão ao Programa DDM - Essencemediacom'
    
    this.brandService = new BrandService()
    this.dealerService = new DealerService()
    this.configurationService = new ConfigurationService()
    this.tokenService = new TokenService()

    this.getAuth(this.$route.query.token)
  },
}
</script>

<style>
  .font-35px {
    font-size: 40px
  } 
  
  .mt-l8 {
    margin-top: -8px;
  }

  .mt-4px {
    margin-top: 4px;
  }

  .mt-31px {
    margin-top: 31px;
  }

  .min-heigth-53vh {
    min-height: 53vh;
  }

  .background-gainsboro {
    background: gainsboro;
  }

  .black-table {
    text-align: center;
  }

  .black-table th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Procurar";
  }

  .w-fit-content{
    width: fit-content;
  }

  body {
    background: #F5F5F5;
  }

  .button-as-link {
    border: none;
    padding: 0px 2px 4px;
  }

  /* Radio Button */
  .div-brand {
    display: contents;
  }

  .label-brand {
    margin: 0px 10px;
  }

  .middle {
    width: 100%;
    text-align: center;
  }
  
  .middle input[type=checkbox] {
    display: none;
  }

  .middle input[type=checkbox]:checked + .box {
    background-color: #007e90;
  }

  .middle input[type=checkbox]:checked + .box div {
    color: white;
    transform: translateY(70px);
  }

  .middle input[type=checkbox]:checked + .box div:before {
    transform: translateY(0px);
    opacity: 1;
  }
  
  .middle .box {
    width: 200px;
    height: 200px;
    background-color: white;
    transition: all 250ms ease;
    will-change: transition;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-weight: 900;
  }

  .middle .box div {
    position: absolute;
    transform: translate(0, 60px);
    left: 0;
    right: 0;
    transition: all 300ms ease;
    font-size: 1.5em;
    user-select: none;
    color: #007e90;
  }

  .middle .box div:before {
    font-size: 1.2em;
    font-family: FontAwesome;
    display: block;
    transform: translateY(-80px);
    opacity: 0;
    transition: all 300ms ease-in-out;
    font-weight: normal;
    color: white;
  }
  
  .middle p {
    color: #FFF;
    font-weight: 400;
  }

  .middle p a { 
    text-decoration: underline;
    font-weight: bold;
    color: #fff;
  }

  .table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
    cursor: default;
  }

  @media only screen and (max-width: 600px) {
    .h2-title {
      font-size: 22px;
      font-weight: 500;
    }
  }
</style>
import { apiUrl } from '@/config/global'

export default class TokenService{
  auth () {
    const _defaultUrl = "https://dealer.blinksessence.com.br"
    const json = {
      url: _defaultUrl
    }
    return fetch(`${apiUrl}/token`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify(json)
    }).then(response => response.json())
  }

  authAdmin (email, password) {
    const _defaultUrl = "https://dealer.blinksessence.com.br"
    const json = {
      url: _defaultUrl,
      email,
      password
    }

    return fetch(`${apiUrl}/token/admin`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify(json)
    }).then(response => response.json())
  }
}
import { apiUrl } from '@/config/global'

export default class DealerService{
  constructor(http){
    this._http = http
  }

  create (dealers) {
    let formData = new FormData()

    for (let i = 0; i < dealers.length; i++) {
      let contactCont = 0
      if (dealers[i].token != null) {
        formData.append(`[${i}].token`, dealers[i].token)
        formData.append(`[${i}].dealerContractId`, dealers[i].dealerContractId)
        formData.append(`[${i}].dealerId`, dealers[i].dealerId)
        formData.append(`[${i}].dealerPrincipalId`, dealers[i].dealerPrincipalId)
      }

      formData.append(`[${i}].brandId`, dealers[i].brandId)

      formData.append(`[${i}].numberStore`, dealers[i].numberStores)
      formData.append(`[${i}].numberModel`, dealers[i].model)

      formData.append(`[${i}].fantasyName`, dealers[i].fantasyName)
      formData.append(`[${i}].companyName`, dealers[i].companyName)
      formData.append(`[${i}].cNPJ`, dealers[i].cNPJ)
      formData.append(`[${i}].business`, dealers[i].business)
      formData.append(`[${i}].municipalRegistration`, dealers[i].municipalRegistration)
      formData.append(`[${i}].stateRegistration`, dealers[i].stateRegistration)
      formData.append(`[${i}].street`, dealers[i].street)
      formData.append(`[${i}].numberAddress`, dealers[i].numberAddress)

      if (dealers[i].complement != null)
        formData.append(`[${i}].complement`, dealers[i].complement)

      formData.append(`[${i}].zipCode`, dealers[i].zipCode)
      formData.append(`[${i}].district`, dealers[i].district)
      formData.append(`[${i}].city`, dealers[i].city)
      formData.append(`[${i}].state`, dealers[i].state)
      formData.append(`[${i}].website`, dealers[i].website)
      
      formData.append(`[${i}].fileSocialContract`, dealers[i].socialContract)

      for (let f = 0; f < dealers[i].financialContacts.length; f++) {
        formData.append(`[${i}].dealerFormContacts[${contactCont}].contactTypeId`, 1)
        if (dealers[i].token != null) {
          formData.append(`[${i}].dealerFormContacts[${contactCont}].dealerContactId`, dealers[i].financialContacts[f].id)
        }
        formData.append(`[${i}].dealerFormContacts[${contactCont}].name`, dealers[i].financialContacts[f].name)
        formData.append(`[${i}].dealerFormContacts[${contactCont}].mail`, dealers[i].financialContacts[f].mail)
        formData.append(`[${i}].dealerFormContacts[${contactCont}].phone`, dealers[i].financialContacts[f].phone)
        
        contactCont++
      }

      for (let l = 0; l < dealers[i].legalRepresentativeContacts.length; l++) {
        formData.append(`[${i}].dealerFormContacts[${contactCont}].contactTypeId`, 2)
        if (dealers[i].token != null) {
          formData.append(`[${i}].dealerFormContacts[${contactCont}].dealerContactId`, dealers[i].legalRepresentativeContacts[l].id)
        }
        formData.append(`[${i}].dealerFormContacts[${contactCont}].name`, dealers[i].legalRepresentativeContacts[l].name)
        formData.append(`[${i}].dealerFormContacts[${contactCont}].mail`, dealers[i].legalRepresentativeContacts[l].mail)
        formData.append(`[${i}].dealerFormContacts[${contactCont}].phone`, dealers[i].legalRepresentativeContacts[l].phone)
        
        contactCont++
      }

      for (let m = 0; m < dealers[i].mediumContacts.length; m++) {
        formData.append(`[${i}].dealerFormContacts[${contactCont}].contactTypeId`, 3)
        if (dealers[i].token != null) {
          formData.append(`[${i}].dealerFormContacts[${contactCont}].dealerContactId`, dealers[i].mediumContacts[m].id)
        }
        formData.append(`[${i}].dealerFormContacts[${contactCont}].name`, dealers[i].mediumContacts[m].name)
        formData.append(`[${i}].dealerFormContacts[${contactCont}].mail`, dealers[i].mediumContacts[m].mail)
        formData.append(`[${i}].dealerFormContacts[${contactCont}].phone`, dealers[i].mediumContacts[m].phone)
        
        contactCont++
      }
    }

    return fetch(`${apiUrl}/api/dealer`, {
      method: 'post',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
      body: formData
    })
  }

  getByToken (token) {
    return fetch(`${apiUrl}/api/dealer/token/${token}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      }
    }).then(response => {
      if (response.status === 200)
        return response.json()
      throw Error('Token Expirado')
    })
  }

  getSocialContract (id) {
    return fetch(`${apiUrl}/api/dealer/socialContract/${id}`, {
      method: "get",
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      }
    }).then(response => response.blob())
  }
}